import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    publicKey: String,
    sessionId: String,
    accountId: String
  }

  connect() {
    this.loadStripe()
  }

  loadStripe() {
    try {
      this.initStripe()
    } catch (exception) {
      if (exception instanceof ReferenceError) {
        if (/Stripe is not defined/.test(exception)) {
          let script = document.createElement("script")
          script.src = "https://js.stripe.com/v3/"
          script.onload = () => {
            this.initStripe()
          }
          document.head.appendChild(script)
        } else {
          throw exception
        }
      }
    }
  }

  initStripe() {
    this.stripe = Stripe(this.publicKeyValue, {
      stripeAccount: this.accountIdValue
    })
  }

  click(event) {
    event.preventDefault()
    const sessionId = this.sessionIdValue
    this.stripe.redirectToCheckout({ sessionId })
  }
}
