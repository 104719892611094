import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["parent", "child"];

  change(event) {
    if (!event.target.checked) {
      this.childTargets.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }
}
