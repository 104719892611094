import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select', 'stageName']
  static values = {
    url: String,
    selected: String
  }

  async connect(){
    const response = await get(this.urlValue)
    const json = await response.json
    this.onFetchSuccess(json)
  }

  onFetchSuccess(stages) {
    this.selectTarget.innerHTML = ''
    this.addOption('', '-')
    stages.forEach((stage) => {
      this.addOption(stage.id, stage.name)
    })
    this.selectTarget.value = this.selectedValue
  }

  addOption(value, label){
    this.selectTarget.insertAdjacentHTML('beforeend', `<option value="${ value }">${ label }</option>`)
  }

  changeSelect(event){
    let stageName = this.selectTarget.options[this.selectTarget.options.selectedIndex].text
    this.stageNameTarget.value = stageName
  }
}
