import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['checkAllCheckbox', 'form', 'spinner', 'searchContainer', 'searchField', 'tableBody']
  static values = {
    ref: String,
    clientUrl: String,
    pageSize: {
      type: Number,
      default: 15
    }
  }

  connect(){
    this.clientList = []
    this.offset = 0
    this.fetchAll()
  }

  async submit(event) {
    event.preventDefault()
    let formData = new FormData(this.formTarget)

    const url = `/integrations/${ this.refValue }/import_clients`
    const response = await post(url, {
      body: formData
    })

    if (response.ok) {
      let flashContainer = document.querySelector('.flash_container');
      flashContainer.innerHTML = "<div class='flash notice' data-controller='flash' data-flash-milliseconds='5000' data-flash-closable='true'>Import has started. We'll notify you by email when the import is complete.</div>";
    }
    this.dispatch('close')
  }

  fetchAll() {
    this.fetchable = true
    this.onStartFetching()
    this.fetchBatch(0, 0)
  }

  async fetchBatch(lengthLastFetch, offset) {
    const response = await get(this.fetchUrl())

    if (response.ok) {
      let json = await response.json
      if (this.fetchable) {
        this.clientList = this.clientList.concat(json)
        this.renderFields(json)
        if (json.length >= this.pageSizeValue) {
          this.offset = this.offset + this.pageSizeValue
          this.fetchBatch(json.length, this.offset)
        } else {
          this.onStopFetching()
        }
      }
    }
  }

  renderFields(data) {
    data.forEach(row => {
      this.renderRow(row)
    })
  }

  rerenderFields(data) {
    this.tableBodyTarget.innerHTML = ''
    this.renderFields(data)
  }

  onStartFetching() {
    this.spinnerTarget.classList.add('block')
    this.searchContainerTarget.classList.add('hidden')
  }

  onStopFetching() {
    this.spinnerTarget.classList.remove('block')
    this.searchContainerTarget.classList.remove('hidden')
  }

  onCheckAll(event) {
    if (this.checkAllCheckboxTarget.checked) {
      this.tableBodyTarget.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
        checkbox.checked = true
      })
    } else {
      this.tableBodyTarget.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
        checkbox.checked = false
      })
    }
  }

  onClickSearch(event) {
    event.preventDefault()
    if (this.searchFieldTarget.value === '') {
      this.rerenderFields(this.clientList)
    } else {
      let searchedClientList = this.clientList.filter(client => Object.values(client).some(attr => ~String(attr).indexOf(this.searchFieldTarget.value)))
      this.rerenderFields(searchedClientList)
    }
  }

  renderRow(row) {
    let content = `<tr>
      <td class="inputs"><input type="checkbox" name="client_ids[]" value="${ row.id }"></td>
      <td>${ row.company_name || '-' }</td>
      <td>${ row.full_name || '-' }</td>
      <td>${ row.email || '-' }</td>
    </tr>`
    this.tableBodyTarget.insertAdjacentHTML('beforeend', content)
  }

  fetchUrl() {
    const url = new URL(this.clientUrlValue)
    // url.search = new URLSearchParams(`per=${ this.pageSizeValue }&offset=${ this.offset }`)
    url.search = new URLSearchParams({'per': this.pageSizeValue, 'offset': this.offset})
    return url
  }
}
