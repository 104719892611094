import { Controller } from '@hotwired/stimulus'
import debounce from "./../libraries/debounce"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  connect() {
    this.doSaveFn = debounce(async () => {
      this.deleteClass()
      let formData = new FormData(this.element)
      const request = new FetchRequest(this.element.getAttribute('method'), this.element.getAttribute('action'), { body: formData})
      const response = await request.perform()
      if (response.ok) {
        this.onPostSuccess()
      }
    }, 300)
  }

  onPostSuccess(event) {
    this.deleteClass()
    this.container.classList.add('saved')
  }

  changeSelectField(event) {
    this.deleteClass()
    this.doSaveFn()
  }

  changeTextField(event) {
    this.doSaveFn()
  }

  deleteClass() {
    this.container.classList.remove('saved')
  }

  get container() {
    return this.element.parentNode
  }
}
