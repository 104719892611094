import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'periodField'];

  onClickDateRange(event) {
    event.preventDefault();
    let element = event.target;
    let children = [...element.closest('ul').children];
    children.forEach((child) => child.classList.remove('selected'));
    element.parentNode.classList.add('selected');
    this.periodFieldTarget.value = element.dataset.period
    this.formTarget.requestSubmit()
  }
}