import { Controller } from '@hotwired/stimulus';
// import { defineCustomElements } from "@duetds/date-picker/dist/loader";

export default class extends Controller {
  static values = {
    submitFormOnChange: Boolean,
    controllerChangeId: String
  }

  static outlets = ['form-frame-submit']

  connect() {
    this.initDatePicker();

    const dateField = this.element.querySelector("duet-date-picker")

    if (this.submitFormOnChangeValue) {
      dateField.addEventListener("duetChange", (e)=> {
        this.element.closest('form').requestSubmit()
      })
    }

    if (this.hasFormFrameSubmitOutlet) {
      dateField.addEventListener("duetChange", (e)=> {
        this.formFrameSubmitOutlet.change()
      })
    }

    setTimeout(()=> {
      const dateFields = Array.from(this.element.querySelectorAll('.duet-date'))
      if (dateFields.length > 1) {
        dateFields.shift()
        dateFields.forEach((dateField) => {
          dateField.remove()
        })
      }
     }, 1)
  }

  initDatePicker() {
    // defineCustomElements(window);
  }
}