import { Controller } from "@hotwired/stimulus";

// this controller takes a value from a select box and put the value as a class of the element.
// It is used when changing cost type of a line item.
export default class extends Controller {
  static values = {
    availableOptions: Array,
    prefixClassName: String,
  };

  updateClass(event) {
    this.availableOptionsValue.forEach((option) => {
      this.element.classList.remove(option);
    });

    this.element.classList.add(
      `${this.prefixClassNameValue}${event.target.value}`,
    );
  }
}
