import { Controller } from "@hotwired/stimulus"

// This controller copies all params in the form and sets them to another form
export default class extends Controller {
  static targets = ['form']
  static values = {
    originalFormId: String
  }

  connect() {
    const originalFormData = new FormData(this.originalForm)
    for (const entry of originalFormData.entries()) {
      const key = entry[0]
      const value = entry[1]

      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = entry[0]
      hiddenField.value = entry[1]
      this.element.insertAdjacentElement('afterbegin', hiddenField)
    }
  }

  get originalForm() {
    return document.getElementById(this.originalFormIdValue)
  }
}
