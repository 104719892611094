import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js';

export default class extends Controller {
  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: 'position'
    },
    responseKind: {
      type: String,
      default: 'html'
    },
    animation: {
      type: Number,
      default: 150
    },
    handle: String,
    draggable: String
  }
  connect() {
    this.sortable = new Sortable(this.element, {
      ...this.options
    })
  }

  async end({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return;

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue;
    const data = new FormData();
    data.append(param, newIndex + 1);
    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue });
  }

  get options() {
    return {
      animation: this.animationValue,
      handle: this.handleValue || undefined,
      onEnd: this.end.bind(this),
      draggable: this.draggableValue || undefined
    }
  }
}
