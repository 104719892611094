import { Controller } from '@hotwired/stimulus'
import FroalaEditor from 'froala-editor'

export default class extends Controller {
  static targets = ['field', 'container']

  static values = {
    attribution: { type: Boolean, default: false },
    buttons: { type: Object, default: {} },
    htmlAllowedStyleProps: { type: Array, default: ['color', 'text-align', 'vertical-align'] },
    htmlAllowedTags: { type: Array, default: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "img", "ul", "ol", "li", "strong", "b", "s", "a", "u", "em", "i", "br", "table", "tbody", "thead", "tr", "th", "td", "iframe", "blockquote", "video", "span"] },
    iconsTemplate: { type: String, default: 'svg' },
    imageAllowTypes: { type: Array, default: ["jpeg", "jpg", "png", "gif"] },
    imageEditButtons: { type: Array, default: ['imageReplace', 'imageAlign', 'imageDisplay', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove'] },
    imageMaxSize: { type: Number, default: 10485760 },
    imagePasteProcess: { type: Boolean, default: true },
    imageUploadParams: { type: Object, default: {} },
    imageUploadUrl: { type: String, default: '' },
    linkAlwaysBlank: { type: Boolean, default: true },
    pasteAllowedStyleProps: { type: Array, default: []},
    requestWithCORS: { type: Boolean, default: true },
    spellcheck: { type: Boolean, default: true },
    submitFormOnContentChanged: { type: Boolean, default: false },
    tableEditButtons: { type: Array, default: ['tableHeader', 'tableFooter', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'] },
    tableCellMultipleStyles: { type: Boolean, default: true },
    tableInsertHelper: { type: Boolean, default: false },
    tableStyles: { type: Object, default: {'fr-dashed-borders': 'Dashed Borders', 'fr-alternate-rows': 'Alternate Rows', 'fr-borderless': 'Borderless', 'fr-small-padding': 'Small Padding' }},
    toolbarInline: { type: Boolean, default: false },
    toolbarSticky: { type: Boolean, default: false },
    typingTimer: { type: Number, default: 500 },
    videoAllowedTypes: { type: Array, default: ['mov', 'mp4', 'qt', 'avi', 'mpg', 'mpeg', 'webm', 'ogg'] },
    videoDefaultAlign: { type: String, default: 'center' },
    videoDefaultWidth: { type: Number, default: 640 },
    videoEditButtons: { type: Array, default: ['videoReplace', 'videoRemove', '|', 'videoDisplay', 'videoAlign', 'videoSize'] },
    videoInsertButtons: { type: Array, default: ['videoBack', '|', 'videoByURL', 'videoEmbed', 'videoUpload'] },
    videoMaxSize: { type: Number, default: 52428800 },
    videoMove: { type: Boolean, default: false },
    videoTextNear: { type: Boolean, default: false },
    videoUpload: { type: Boolean, default: false },
    videoUploadParams: { type: Object, default: {} },
    videoUploadUrl: { type: String, default: '' },
    wordAllowedStyleProps: { type: Array, default: []},
    wordPasteModal: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    enter: { type: String, default: 'p'}

  }

  connect () {
    this.initEditor()
  }

  disconnect() {
    this.editor.destroy()
  }

  initEditor() {
    this.editor = new FroalaEditor(this.fieldTarget, {
      attribution: this.attributionValue,
      htmlAllowedStyleProps: this.htmlAllowedStylePropsValue,
      htmlAllowedTags: this.htmlAllowedTagsValue,
      iconsTemplate: this.iconsTemplateValue,
      imageAllowTypes: this.imageAllowTypesValue,
      imageEditButtons: this.imageEditButtonsValue,
      imageMaxSize: this.imageMaxSizeValue,
      imagePasteProcess: this.imagePasteProcessValue,
      imageUploadParams: Object.fromEntries(Object.entries(this.imageUploadParamsValue).filter(([key, value]) => value !== null)),
      imageUploadURL: this.imageUploadUrlValue,
      linkAlwaysBlank: this.linkAlwaysBlankValue,
      pasteAllowedStyleProps: this.pasteAllowedStylePropsValue,
      requestWithCORS: this.requestWithCORSValue,
      requestHeaders: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
      },
      spellcheck: this.spellcheckValue,
      tableEditButtons: this.tableEditButtonsValue,
      tableCellMultipleStyles: this.tableCellMultipleStylesValue,
      tableInsertHelper: this.tableInsertHelperValue,
      tableStyles: this.tableStylesValue,
      toolbarButtons: this.buttonsValue,
      toolbarInline: this.toolbarInlineValue,
      toolbarSticky: this.toolbarStickyValue,
      typingTimer: this.typingTimerValue,
      videoAllowedTypes: this.videoAllowedTypesValue,
      videoDefaultAlign: this.videoDefaultAlignValue,
      videoDefaultWidth: this.videoDefaultWidthValue,
      videoEditButtons: this.videoEditButtonsValue,
      videoInsertButtons: this.videoInsertButtonsValue,
      videoMaxSize: this.videoMaxSizeValue,
      videoMove: this.videoMoveValue,
      videoTextNear: this.videoTextNearValue,
      videoUpload: this.videoUploadValue,
      videoUploadParams: Object.fromEntries(Object.entries(this.videoUploadParamsValue).filter(([key, value]) => value !== null)),
      videoUploadURL: this.videoUploadUrlValue,
      wordAllowedStyleProps: this.wordAllowedStylePropsValue,
      wordPasteModal: this.wordPasteModalValue,
      linkInsertButtons: ['linkBack'],
      enter: (this.enterValue === 'p' ? FroalaEditor.ENTER_P : FroalaEditor.ENTER_BR)
    }, ()=> {
      this.editor.events.on('contentChanged', ()=> {
        this.fieldTarget.innerHTML = this.editor.html.get(true)
        if (this.submitFormOnContentChangedValue) {
          this.fieldTarget.closest('form').requestSubmit()
        }
      })
      this.editor.events.on('commands.after', (cmd)=> {
        if (cmd === 'html' && this.hasContainerTarget) {
          this.containerTarget.classList.toggle('html_show')
        }
      })
      this.editor.events.on('focus', (event)=> {
        if (this.hasContainerTarget) {
          this.containerTarget.classList.add('focus')
        }
      })
      this.editor.events.on('blur', (event)=> {
        if (this.hasContainerTarget) {
          this.containerTarget.classList.remove('focus')
        }
      })
      if (this.autofocusValue) {
        this.editor.events.focus();
      }
      if (this.disabledValue) {
        this.editor.edit.off();
        this.toolbar.edit.disable();
      }
    })
  }
}