import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["primary", "dependent"]
  static values = { optionAttributeName: String }

  change(event) {
    if (this.hasDependentTarget) {
      const select = event.target
      const selectedOption = select.options[select.selectedIndex]
      const dependentValue = selectedOption.dataset[`${this.optionAttributeNameValue}`]
      if (dependentValue) {
        this.dependentTarget.value = dependentValue
        this.dependentTarget.dispatchEvent(new Event('change'))
      }
    }
  }
}
