import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    eligibleToSign: {
      type: Boolean,
      default: false
    }
  }

  disableCheckboxes() {
    const checkboxes = this.element.querySelectorAll('[data-action="click->recipient-upgrade-multiple-signatures#toggleCheckbox"]');
    checkboxes.forEach((checkbox) => {
      if (!checkbox.checked) {
        checkbox.disabled = true;
      }
    });
  }

  onChangeCheckbox(event) {
    if (event.target.checked) {
      if (!this.eligibleToSignValue && (this.checkedCheckboxes().length > 1)) {
        this.checkedCheckboxes().forEach((checkbox, index) => {
          if (checkbox !== event.target) {
            checkbox.checked = false;
          }
        });
      }
    }
  }

  checkedCheckboxes() {
    return this.element.querySelectorAll('input[type="checkbox"].eligible_to_sign:checked');
  }
}
