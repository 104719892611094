import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['link', 'input']

  change(event) {
    const url = new URL(this.linkTarget.getAttribute('href'), document.location)
    this.inputTargets.filter(element => !element.checked).forEach((element) => {
      url.searchParams.delete(element.getAttribute('name'))
    })
    this.inputTargets.filter(element =>  element.checked).forEach((element) => {
      url.searchParams.append(element.getAttribute('name'), element.value)
    })

    this.linkTarget.setAttribute('href', url.toString())
  }
}
