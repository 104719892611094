import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  collapse(event) {
    event.preventDefault()
    this.collapseMenu()
  }

  collapseMenu() {
    this.body.classList.toggle('menu_collapsed')
    if (this.isCollapsed){
      localStorage.setItem('menuCollapsed', 'true')
    } else {
      localStorage.setItem('menuCollapsed', 'false')
    }
  }

  get body() {
    return document.querySelector('body')
  }

  get isCollapsed() {
    return this.body.classList.contains('menu_collapsed')
  }
}
