import { Controller } from '@hotwired/stimulus'
import debounce from './../libraries/debounce'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'inputField', 'searchResults', 'background', 'resultsContainer', 'emptyResult', 'spinner']

  connect(){
    this.searchFn = debounce(() => {
      this.search()
    }, 250)
  }

  onFocus(){
    this.element.classList.add(this.selectedClass)
  }

  onBlur(){
    setTimeout(() =>{
      this.element.classList.remove(this.selectedClass)
    }, 10);

  }

  onPaste(){
    setTimeout(() =>{
      this.search(this.inputFieldTarget.value)
    }, 1);
  }

  onSubmit(event) {
    event.preventDefault()
  }

  onKeyPress(event){
    if (![0, 13].includes(event.which)) {
      if (String.fromCharCode(event.which) !== "") {
        this.searchFn()
      }
    }
  }

  onKeyDown(event){
    switch (event.which) {
      case 8:
        this.searchFn()
        break
      case 13:
        this.formTarget.submit()
        break
    }
  }

  onClickClose(event) {
    event.preventDefault()
    // delete search results
    this.inputFieldTarget.value = ''
    this.removeResultsContainer()
  }

  showEmptyResults() {
    this.removeEmptyResults()
    const content = this.emptyResultTarget.innerHTML
    this.element.querySelector('ul.results').insertAdjacentHTML('beforeend', content)
  }

  async search(query) {
    this.onStartSearch()

    query = this.inputFieldTarget.value
    const url = `${ this.data.get('apiUrl') }?query=${ query }`

    const response = await get(url)
    if (response.ok) {
      this.showResults(await response.text)
    }
  }

  onStartSearch() {
    this.removeResults()
    this.backgroundTarget.classList.add('active')
    this.searchResultsTarget.classList.add('active')
    this.spinnerTarget.classList.add('active')
  }


  showResults(html) {
    this.spinnerTarget.classList.remove('active')
    this.resultsContainerTarget.innerHTML = html
  }

  onClickBackground() {
    this.removeResultsContainer()
  }

  removeResultsContainer() {
    this.backgroundTarget.classList.remove('active')
    this.searchResultsTarget.classList.remove('active')
    this.removeResults()
  }

  removeResults() {
    this.resultsContainerTarget.innerHTML = ''
  }

  get selectedClass() {
    return this.data.get('selectedClass')
  }
}