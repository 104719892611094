import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = {
    allowEmpty: { type: Boolean, default: false },
    create: String,
    createUrl: String,
    createMethod: { type: String, default: 'get' },
    createParams: Object,
    createResponseKind: { type: String, default: 'turbo-stream' }
  }

  connect() {
    let config = {
      plugins: [],
      valueField: 'value',
      allowEmptyOption: true,
    }

    if (this.hasCreateValue) {
      config['onChange'] = (value) => { this.onChange(value) }
    }

    this.select = new TomSelect(this.element, config)
  }

  async onChange(value) {
    if (value == this.createValue) {
      this.select.clear(true)
      const searchParams = new URLSearchParams(this.createParamsValue)
      const request = new FetchRequest(this.createMethodValue, this.createUrlValue, {
        query: searchParams,
        responseKind: this.createResponseKindValue
      })
      const response = await request.perform()
    }
  }
}