import { Controller } from '@hotwired/stimulus'
import DonutChart from './../libraries/donut_chart.js'

export default class extends Controller {
  static targets = ['canvas']

  connect(){
    let rate = this.data.get('rate')
    let chartValues = [{
      percent: (1 - rate) * 100,
      color: this.data.get('fallback-color')
    }, {
      percent: rate * 100,
      color: this.data.get('color')
    }]

    let donut = new DonutChart(this.canvasTarget, this.data.get('chartLineWidth'), chartValues)
    donut.render()
  }
}