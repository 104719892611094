import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['trigger', 'field']

  toggle(event) {
    event.preventDefault()
    if (this.fieldTarget.value == 'false') {
      this.fieldTarget.value = 'true'
    } else {
      this.fieldTarget.value = 'false'
    }
    this.fieldTarget.dispatchEvent(new Event('change'))
  }
}
