import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }
  static targets = ["email", "name"]

  connect() {
    this.focusOnAutofocusField();
  }

  pressEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.addRecipient(event);
    }
  }

  addRecipient(event) {
    event.preventDefault()

    const formData = new FormData();
    formData.append('documents_recipient[email]', this.emailTarget.value);
    formData.append('documents_recipient[name]', this.nameTarget.value);

    post(this.urlValue, {
      body: formData,
      responseKind: 'turbo-stream'
    });
  }

  focusOnAutofocusField(){
    const autofocusElement = this.element.querySelector('[autofocus]');
    if (autofocusElement) {
      autofocusElement.focus();
    }
  }
}
