import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'cardHolderField', 'submitButton', 'feedback']
  static values = {
    disableWith: String
  }

  connect() {
    this.loadStripe()
  }

  loadStripe() {
    try {
      this.stripe = Stripe(app.stripe_public_key)
      this.initStripe();
    } catch(exception) {
      if (exception instanceof ReferenceError) {
        if (/Stripe is not defined/.test(exception)) {
          let script = document.createElement('script');
          script.src = 'https://js.stripe.com/v3/';
          script.onload = ()=>{
            this.stripe = Stripe(app.stripe_public_key)
            this.initStripe();
          }
          document.head.appendChild(script);
        } else {
          throw exception;
        }
      }
    }
  }

  async initStripe() {
    this.card = this.initStripeCard(this.stripe.elements());
    this.card.mount('#card-element');
  }

  submitForm(event) {
    event.preventDefault()
    event.stopPropagation()
    this.onBeforeSubmitForm();


    const billingDetails = {
      name: this.cardHolderFieldTarget.value
    }

    if (billingDetails.name == '') {
      this.onStripeError('Cardholder name needs to be filled in');
    } else {
      this.stripe.createPaymentMethod("card", this.card, { billing_details: billingDetails}).then(result => {
        this.stripeResponseHandler(result)
      });
    }
  }

  async stripeResponseHandler(result) {
    if (result.error) {
      this.onStripeError(result.error.message)
      return
    }
    const paymentMethod = result.paymentMethod
    let formData = new FormData()
    formData.append("payment_method", paymentMethod.id)

    let response = await post('/register/create_customer')
    if (!response.ok) {
      this.onStripeError('Something has gone wrong. Please contact support@nusii.com')
      return
    }

    response = await post('/register/create_subscription', {
      body: formData
    })

    if (!response.ok) {
      const json = response.json
      this.onStripeError(json.error)
      return
    }

    const subscription = await response.json
    const { pending_setup_intent, status } = subscription
    if ((status === "active" || status === "trialing" || status === 'unpaid') && pending_setup_intent == null) {
      this.formTarget.submit();
    } else {
      const { client_secret, status } = subscription.pending_setup_intent
      if (status === 'requires_action') {
        const result = await this.stripe.handleCardSetup(client_secret)
        if (result.error) {
          this.onStripeError(result.error.message);
        } else {
          this.formTarget.submit();
        }
      } else if (status === 'requires_payment_method') {
        this.onStripeError('Adding Credit Card failed, please try again with a different card.');
      }
    }
  }

  onBeforeSubmitForm() {
    this.removeErrors();
    this.submitButtonTarget.disabled = true;
    this.submitButtonText = this.submitButtonTarget.innerHTML;
    this.submitButtonTarget.innerHTML = this.disableWithValue;
  }

  onAfterSubmitForm() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.innerHTML = this.submitButtonText;
  }

  onStripeError(message) {
    this.onAfterSubmitForm();
    this.feedbackTarget.innerHTML = message;
    this.feedbackTarget.classList.remove('hidden')
  }

  removeErrors() {
    this.feedbackTarget.classList.add('hidden')
    this.feedbackTarget.innerHTML = '';
  }

  initStripeCard(elements) {
    const style = {
      base: {
        iconColor: '#3FD9B2',
        color: '#555555',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: 'proxima-nova, Arial',
        fontSize: '14px',
        '::placeholder': {
          color: '#CCCCCC'
        }
      },
      invalid: {
        iconColor: '#e1595e',
        color: '#e1595e'
      }
    }
    return elements.create('card', { style: style });
  }
}
