import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    if (this.closable) {
      this.addCloseButton()
    }
    this.closeTimeout = setTimeout(this.closeFlash.bind(this), this.milliseconds)
    this.deleteTimeout = setTimeout(this.deleteFlash.bind(this), this.milliseconds + 500)
    this.slideInTimeout = setTimeout(this.slideInFlash.bind(this), 10)
  }

  disconnect() {
    clearTimeout(this.closeTimeout)
    clearTimeout(this.deleteTimeout)
    clearTimeout(this.slideInTimeout)
  }

  slideInFlash() {
    this.element.classList.add('slide_in')
  }

  closeFlash() {
    this.element.classList.add('slide_out')
  }

  onClickClose(event) {
    event.preventDefault()
    this.element.classList.add('slide_out')
    clearTimeout(this.closeTimeout)
  }

  deleteFlash() {
    this.element.parentNode.removeChild(this.element)
  }


  addCloseButton() {
    let closeButton = '<a href="#" class="close" data-action="flash#onClickClose">&times;</a>'
    this.element.insertAdjacentHTML('beforeend', closeButton)
  }


  get milliseconds() {
    return parseInt(this.data.get('milliseconds') || 3000)
  }

  get closable() {
    return (this.data.get('closable') || 'false') === 'true'
  }
}
