import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="checkbox-acts-as-radio"
export default class extends Controller {
  static targets = ["checkbox"];

  connect() {}

  select(event) {
    const clickedCheckbox = event.target;

    if (clickedCheckbox.checked) {
      this.checkboxTargets.forEach((checkbox) => {
        if (checkbox !== clickedCheckbox && checkbox.checked) {
          checkbox.checked = false;
          checkbox.form.requestSubmit();
        }
      });
    }
  }
}
