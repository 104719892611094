export default class DonutChart {
  constructor(canvas, lineWidth, chartValues) {
    this.canvas = canvas;
    this.lineWidth = lineWidth;
    this.chartValues = chartValues;
    this.radius = (this.canvas.width - lineWidth) / 2;
    this.xCenter = this.canvas.width / 2;
    this.yCenter = this.canvas.height / 2;
    this.context = this.canvas.getContext("2d");
    this.context.lineWidth = lineWidth;
  }

  drawValues() {
    let context = this.context;
    let offsetRadius = -0.5 * Math.PI;
    let startRadius = offsetRadius;
    let counterClockwise = true;

    this.chartValues.forEach((value) => {
      context.beginPath();
      context.strokeStyle = value.color;
      let endRadians = startRadius - (Math.PI * 2) * value.percent / 100;
      context.arc(this.xCenter, this.yCenter, this.radius,
      startRadius, endRadians, counterClockwise);
      context.stroke();
      startRadius = endRadians;
    });
  }

  render() {
    this.drawValues()
  }
}