import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  clearForm(event) {
    event.preventDefault()
    this.element.querySelectorAll('input, select').forEach((element) => {
      let fieldType = element.type.toLowerCase();
      let customEvent = new Event('input');

      switch (fieldType) {
      case "text":
      case "password":
      case "textarea":
      case "hidden":
      case "email":
        element.value = ""
        element.dispatchEvent(customEvent);
        break
      case "radio":
      case "checkbox":
        if (element.checked) {
          element.checked = false
          element.dispatchEvent(customEvent);
        }
        break
      case "select-one":
      case "select-multi":
        element.value = ''
        element.dispatchEvent(customEvent);
        break
      default:
        break
      }
    })
    Turbo.clearCache();
    let form = this.element.closest('form');
    if (form) {
      form.requestSubmit();
    }
  }
}