import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    animationDuration: {
      type: Number,
      default: 10000
    }
  }
  connect() {
    this.timeout = null

    const observer = new MutationObserver(this.observerCallback.bind(this))
    observer.observe(this.element, this.observerConfig)
  }

  disconnect() {
    this.clearTimeout()
  }

  deleteClass() {
    this.element.classList.remove('saved')
  }

  clearTimeout() {
    clearTimeout(this.timeout)
  }

  observerCallback(mutationsList, observer) {
    for(let mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        if (mutation.target.classList.contains('saved')) {
          this.clearTimeout()
          this.timeout = setTimeout(this.deleteClass.bind(this), this.animationDurationValue)
        }
      }
    }
  }

  get observerConfig() {
    return { attributes: true, childList: false, subtree: false }
  }
}
