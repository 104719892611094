import { Controller } from '@hotwired/stimulus'
import HexToRgb from './../libraries/hex_to_rgb'

export default class extends Controller {
  static targets = ['backgroundOverlay', 'loading', 'loadingTemplate']
  static values = {
    color: String,
    opacity: Number
  }

  connect(){
    if (this.hasColorValue) {
      const { r, g, b } = HexToRgb.convert(this.colorValue)
      this.setBackgroundColor(r, g, b)
    }
  }

  setBackgroundColor(r, g, b) {
    this.backgroundOverlayTarget.style.backgroundColor = `rgba(${ r }, ${ g },${ b }, ${ this.opacity })`
  }

  onBackgroundColorChanged(event) {
    this.colorValue = event.detail.hex
    const { r, g, b} = HexToRgb.convert(this.colorValue)
    this.setBackgroundColor(r, g, b)
  }

  onBackgroundOpacityChanged(event) {
    this.opacity = event.detail.opacity
    const { r, g, b } = HexToRgb.convert(this.colorValue)
    this.setBackgroundColor(r, g, b)
  }

  setBackgroundImage(url) {
    this.element.style.backgroundImage = `url(${ url })`
  }

  onLoading() {
    this.element.insertAdjacentHTML('afterbegin', this.loadingTemplateTarget.innerHTML)
  }

  onDoneLoading() {
    this.loadingTarget.parentNode.removeChild(this.loadingTarget)
  }

  set opacity(value) {
    this.opacityValue = value * 100
  }

  get opacity() {
    return this.opacityValue / 100
  }

  get alpha() {
    return 1 - this.opacity
  }
}