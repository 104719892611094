import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    targetSelector: String
  }

  remove(event) {
    event.preventDefault();
    const targetElement = this.hasTargetSelectorValue ? document.querySelector(this.targetSelectorValue) : this.element;
    if (targetElement) {
      targetElement.remove();
    }
  }
}
