import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["container", "backdrop", "panel", "closeButton", "mainView"]

  toggle() {
    if (this.panelTarget.classList.contains('hidden')) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    enter(this.backdropTarget)
    enter(this.panelTarget)
    this.backdropTarget.style.height = `${ this.backdropTarget.offsetParent.scrollHeight }px`
  }

  hide() {
    leave(this.backdropTarget)
    leave(this.panelTarget)
  }
}
