import { Controller } from "@hotwired/stimulus"
import debounce from "./../libraries/debounce"

export default class extends Controller {
  static targets = ['form', 'frame']
  static values = {
    delay: { type: Number, default: 300 },
    url: String
  }

  connect() {
    this.doDebouncedSave = debounce(async () => {
      this.fetchFrame()
    }, this.delayValue)
  }

  debouncedChange(event) {
    this.doDebouncedSave()
  }

  change(event) {
    this.fetchFrame()
  }

  fetchFrame() {
    const formData = new FormData(this.formTarget)
    const frameSrc = new URL(this.urlValue)
    frameSrc.search = new URLSearchParams(formData)
    this.frameTarget.src = frameSrc
  }
}
