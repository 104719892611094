import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'field']
  static values = { confirmation: String }

  connect() {
    this.enableOrDisableSubmitButton()
  }

  change(event) {
    this.enableOrDisableSubmitButton()
  }

  enableOrDisableSubmitButton(){
    if (this.fieldTarget.value === this.confirmationValue) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}