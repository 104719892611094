import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['periodSlider']
  static values = {
    currentPeriod: String
  }

  connect() {
    if (this.currentPeriodValue == 'Annually'){
      this.periodSliderTarget.checked = true
    }
    this.showPriceLabels()
  }

  changePeriod(event) {
    if (this.periodSliderTarget.checked) {
      this.currentPeriodValue = 'Annually'
    } else {
      this.currentPeriodValue = 'Monthly'
    }
  }

  showPriceLabels() {
    if (this.currentPeriodValue == 'Monthly' || this.currentPeriodValue == 'LifeTime') {
      this.element.querySelectorAll('.plan[data-period=monthly]').forEach((element)=>{
        element.classList.remove('hidden')
      })
      this.element.querySelectorAll('.plan[data-period=yearly]').forEach((element)=>{
        element.classList.add('hidden')
      })
    } else {
      this.element.querySelectorAll('.plan[data-period=monthly]').forEach((element)=>{
        element.classList.add('hidden')
      })
      this.element.querySelectorAll('.plan[data-period=yearly]').forEach((element)=>{
        element.classList.remove('hidden')
      })
    }
  }

  currentPeriodValueChanged(value, previousValue) {
    this.showPriceLabels()
  }
}
