import { Controller } from "@hotwired/stimulus"
import '@claviska/jquery-minicolors/jquery.minicolors.min.js'
import ColorValidator from './../libraries/color_validator.js'

export default class extends Controller {
  static targets = ['item']
  static values = {
    changeDelay: {
      type: Number,
      default: 100
    },
    letterCase: {
      type: String,
      default: 'uppercase'
    }
  }

  connect() {
    this.initColorPicker()
  }

  initColorPicker() {
    $(this.itemTarget).minicolors({
      changeDelay: this.changeDelayValue,
      letterCase: this.letterCaseValue,
      change: (value, opacity) => {
        this.itemTarget.classList.remove('error')
        if (ColorValidator.isValid(value)) {
          this.itemTarget.form.requestSubmit()
        } else {
          this.itemTarget.classList.add('error')
        }
      }
    })
  }

  onFocus(event) {
    this.itemTarget.classList.remove('error')
  }
}
