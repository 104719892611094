import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    resourceName: String,
    paramName: String,
    responseKind: {
      type: String,
      default: 'turbo-stream'
    },
    requestType: {
      type: String,
      default: 'post'
    }
  }
  static targets = ['fileInput']

  dragover(event) {
    event.preventDefault()
    this.element.classList.add('dragging')
  }

  dragenter(event) {
    event.preventDefault()
    this.element.classList.add('dragging')
  }

  dragleave(event) {
    event.preventDefault()
    this.element.classList.remove('dragging')
  }

  drop(event) {
    event.preventDefault()
    this.element.classList.remove('dragging')

    let files = Array.from(event.dataTransfer.files)
    files.forEach(file => {
      this.uploadFile(file)
    })
  }

  onClickOpenFile(event) {
    event.preventDefault()
    if (this.hasFileInputTarget) {
      this.fileInputTarget.click()
    }
  }

  onChangeFileInput(event) {
    const file = event.target.files[0]
    this.uploadFile(file)
  }

  async uploadFile(file) {
    this.loading()
    let formData = new FormData()
    const param = this.hasResourceNameValue ? `${ this.resourceNameValue }[${ this.paramNameValue }]` : this.paramNameValue;
    formData.append(param, file)

    const request = new FetchRequest(this.requestTypeValue, this.urlValue, {
      body: formData,
      responseKind: this.responseKindValue
    })
    const response = await request.perform()

    if (response.ok) {
      if (this.responseKindValue === 'json') {
        const json = await response.json
        onSuccess(json)
      }
    } else {
      console.log('error', response.status)
    }
    this.doneLoading()
  }

  loading() {
    const event = new CustomEvent("dropzone-loading")
    window.dispatchEvent(event)
  }

  doneLoading(){
    const event = new CustomEvent("dropzone-done-loading")
    window.dispatchEvent(event)
  }

  onSuccess(data) {
    const event = new CustomEvent("dropzone-success", { detail: data })
    window.dispatchEvent(event)
  }
}
