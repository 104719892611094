// <div data-controller="modals--confirm" data-modals--confirm-allow-background-close="true">
//   <a href="#" class="clicker" data-action="modals--confirm#open" class="">
//     <span>Open Modal</span>
//   </a>

//   <div data-modals--confirm-target="container" data-action="click->modals--confirm#closeBackground keyup@window->modals--confirm#closeWithKeyboard" class="modal_container hidden" style="z-index: 9999;">
//     <div class="modal_dialog">
//       <header>
//         Title
//         <a href="#" class="close" data-action="modals--confirm#close">&times;</a>
//       </header>
//       <div class="modal_content">
//         <h2>Title Modal</h2>
//         <p>lorem ipsum</p>
//       </div>
//       <footer>
//         <button class="" data-action="modals--confirm#close">OK</button>
//       </footer>
//     </div>
//   </div>
// </div>

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']
  static values = {
    autoOpen: Boolean
  }

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true'

    if (this.autoOpenValue) {
      this.open()
    }
  }

  open(event) {
    if (event) {
      event.preventDefault()
      event.target.blur()
    }

    this.lockScroll()
    this.showModal()
    this.element.dispatchEvent(new Event("opened"))
  }

  close(event) {
    if (event) {
      event.preventDefault()
    }

    this.unlockScroll()
    this.hideModal()
    this.element.dispatchEvent(new Event("closed"))
  }

  ok(event) {
    event.preventDefault()

    this.element.dispatchEvent(new Event("ok"))
  }

  showModal() {
    this.containerTarget.classList.remove(this.toggleClass)
  }

  hideModal() {
    if (this.deleteOnClose) {
      this.element.remove()
    } else {
      this.containerTarget.classList.add(this.toggleClass)
    }
  }

  closeBackground(event) {
    event.preventDefault
    if (this.allowBackgroundClose && event.target === this.containerTarget) {
      this.close(event)
    }
  }

  closeWithKeyboard(event) {
    if (event.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(event)
    }
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    this.saveScrollPosition()

    document.body.classList.add('lock_scrolling')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null
    document.body.classList.remove('lock_scrolling')
    this.restoreScrollPosition()
    document.body.style.top = null
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition
  }

  get deleteOnClose() {
    return this.data.get('deleteOnClose') === 'true'
  }
}
