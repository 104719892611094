import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]
  static values = {
    offset: {
      type: Number,
      default: 0
    }
  }

  connect() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => { this.update(); });
    });

    this.headlines.forEach((headline) => this.observer.observe(headline));

    this.update();
  }

  get headlines() {
    return [...this.itemTargets].map((element) => document.querySelector(element.hash));
  }

  update() {
    this.headlines.forEach((headline) => {
      let bounds = headline.getBoundingClientRect();
      let link = this.element.querySelector(`a[href="#${ headline.id }"]`);
      let item = link.parentElement;

      if(bounds.top < 0) {
        item.classList.add('viewed');
      } else {
        item.classList.remove('viewed');
      }
    })
  }

  clickItem(event) {
    event.preventDefault();
    history.pushState({}, "", event.target.hash)
    let element = document.querySelector(event.target.hash)
    let offset = element.offsetTop - this.offsetValue
    scroll({
      top: offset,
      behavior: 'smooth'
    })
  }
}
