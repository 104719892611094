import { Controller } from "@hotwired/stimulus"
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = [
    'canvas',
    'drawLink',
    'typeLink',
    'clearLink',
    'typedPad',
    'signatureImageDataField',
    'signaturePadContainer'
  ]
  static values = {
    forceSignature: Boolean,
    dotSize: {
      type: Number,
      default: 1.0
    },
    minWidth: {
      type: Number,
      default: 0.2
    },
    maxWidth: {
      type: Number,
      default: 2
    }
  }

  connect() {
    this.signaturePad = null
    if (this.forceSignatureValue) {
      this.showSignaturePad()
    } else {
      this.showTyped()
    }
  }

  disconnect() {
    this.signaturePad?.off()
  }

  initSignaturePad() {
    this.signaturePad = new SignaturePad(this.canvasTarget, {
      dotSize: this.dotSizeValue,
      minWidth: this.minWidthValue,
      maxWidth: this.maxWidthValue
    })

    this.signaturePad.addEventListener("endStroke", this.onEndStroke.bind(this))
  }

  onEndStroke() {
    this.signatureImageDataFieldTarget.value = this.signaturePad.toDataURL('image/svg+xml')
  }

  clear(event) {
    event.preventDefault()
    this.signaturePad.clear()
  }

  clickDraw(event) {
    event.preventDefault()
    this.showSignaturePad()
  }

  clickType(event) {
    this.showTyped()
    event.preventDefault()
  }

  resizeWindow() {
    // This should be resizing container instead
  }

  resizeCanvas() {
    let ratio =  Math.max(window.devicePixelRatio || 1, 1);

    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;
    this.canvasTarget.getContext("2d").scale(ratio, ratio);

    this.signaturePad.clear();
  }

  onTypeSignator(event) {
    let value = event.currentTarget.value
    this.typedPadTarget.querySelector('div.typed').innerText = value
  }

  showSignaturePad() {
    if (!this.forceSignatureValue) {
      this.typeLinkTarget.classList.remove('hidden')
      this.drawLinkTarget.classList.add('hidden')
      this.typedPadTarget.classList.add('hidden')
    }
    this.clearLinkTarget.classList.remove('hidden')
    this.signaturePadContainerTarget.classList.remove('hidden')
    const screenWidth = window.innerWidth - 80
    if (screenWidth < 600) {
      this.signaturePadContainerTarget.style.width = `${screenWidth}px`
      this.canvasTarget.width = screenWidth
    }
    this.initSignaturePad()
    this.resizeCanvas()

    if (this.hasSignatureImageDataFieldTarget && this.signatureImageDataFieldTarget.value) {
      this.signaturePad.fromDataURL(this.signatureImageDataFieldTarget.value)
    }
  }

  showTyped() {
    this.signaturePad?.clear()
    this.signaturePad?.removeEventListener("endStroke", this.onEndStroke.bind(this))
    this.signaturePad = null
    this.typeLinkTarget.classList.add('hidden')
    this.drawLinkTarget.classList.remove('hidden')
    this.clearLinkTarget.classList.add('hidden')
    this.typedPadTarget.classList.remove('hidden')
    this.signaturePadContainerTarget.classList.add('hidden')
  }
}
