import { Controller } from '@hotwired/stimulus'
import HexToRgb from './../libraries/hex_to_rgb'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['color', 'dynamicColor', 'dynamicColorInput']
  static values = {
    url: String,
    resourceName: String,
    paramNameColor: String,
    paramNameDynamicColor: String,
    paramNameOpacity: String,
    respondKind: {
      type: String,
      default: 'json'
    }
  }

  connect(){
    this.initDynamicColor()
  }

  async onClickColor(event) {
    let target = event.target
    let value = target.dataset.value

    let formData = new FormData()
    const paramDynamic = this.hasResourceNameValue ? `${ this.resourceNameValue }[${ this.paramNameDynamicColorValue }]` : this.paramNameDynamicColorValue;
    const paramColor = this.hasResourceNameValue ? `${ this.resourceNameValue }[${ this.paramNameColorValue }]` : this.paramNameColorValue;
    formData.append(paramDynamic, value)
    formData.append(paramColor, value)
    const request = await patch(this.urlValue, {
      body: formData,
      responseKind: 'json'
    })
    if (request.ok) {
      this.deselectAllColors()
      target.classList.add('selected')
      const customEvent = new CustomEvent("cover-color-changed", { detail: { hex: value } })
      window.dispatchEvent(customEvent)
    }
  }

  deselectAllColors() {
    this.colorTargets.forEach(color => {
      color.classList.remove('selected')
    })
    this.dynamicColorTarget.classList.remove('selected')
  }

  async onChangeRange(event) {
    let opacity = event.target.value
    let formData = new FormData()
    const param = this.hasResourceNameValue ? `${ this.resourceNameValue }[${ this.paramNameOpacityValue }]` : this.paramNameOpacityValue;
    formData.append(param, opacity)
    const request = await patch(this.urlValue, {
      body: formData,
      responseKind: this.responseKindValue
    })
  }

  onInputRange(event) {
    let opacity = parseInt(event.target.value) / 100
    const customEvent = new CustomEvent("cover-opacity-changed", { detail: { opacity: opacity } })
    window.dispatchEvent(customEvent)
  }

  initDynamicColor() {
    $(this.dynamicColorInputTarget).minicolors({
      changeDelay: 100,
      letterCase: 'uppercase',
      position: 'bottom right',
      change: async (value, opacity)=> {
        let formData = new FormData()
        const paramDynamic = this.hasResourceNameValue ? `${ this.resourceNameValue }[${ this.paramNameDynamicColorValue }]` : this.paramNameDynamicColorValue;
        const paramColor = this.hasResourceNameValue ? `${ this.resourceNameValue }[${ this.paramNameColorValue }]` : this.paramNameColorValue;
        formData.append(paramDynamic, value)
        formData.append(paramColor, value)
        const request = await patch(this.urlValue, {
          body: formData,
          responseKind: this.responseKindValue
        })
        if (request.ok) {
          this.deselectAllColors()
          this.dynamicColorTarget.classList.add('selected')
          const customEvent = new CustomEvent("cover-color-changed", { detail: { hex: value } })
          window.dispatchEvent(customEvent)
        }
      }
    })
  }

  onClickDynamicColor(event) {
    $(this.dynamicColorInputTarget).minicolors('show')
  }
}