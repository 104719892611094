import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  click(event) {
    event.preventDefault()
    Beacon('open')
    setTimeout(() => {
      const beaconContainer = document.getElementById('beacon-container');
      if (!beaconContainer) {
        alert('Our support beacon is likely being blocked by an ad-blocker or browser settings. Turn off your ad-blocker or send us your question to support@nusii.com');
      }
    }, 1500);
  }
}
