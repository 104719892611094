import { Controller } from "@hotwired/stimulus"
import debounce from "./../libraries/debounce"

export default class extends Controller {
  static values = {
    delay: { type: Number, default: 300 }
  }
  connect() {
    this.doDebouncedSave = debounce(async () => {
      this.form.requestSubmit()
    }, this.delayValue)
  }

  change(){
    this.doDebouncedSave()
  }
  submit(event) {
    event.preventDefault()
    this.element.requestSubmit()
  }
  get form () {
    if (this.element.tagName === 'FORM') {
      return this.element
    } else {
      return this.element.closest('form')
    }
  }
}
