import { Controller } from '@hotwired/stimulus'
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String,
    param: String,
    target: String,
    params: Object
  }

  change(event) {
    let url = this.urlValue
    if (url.includes(':id')) {
      url = this.urlValue.replace(':id', event.target.selectedOptions[0].value)
    }
    let params = new URLSearchParams(this.paramsValue)
    params.append("target", this.targetValue)
    if (this.hasParamValue) {
      params.append(this.paramValue, event.target.selectedOptions[0].value)
    }

    get(`${ url }?${ params }`, {
      responseKind: "turbo-stream"
    })
  }
}