import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(this.appear.bind(this), 1)
  }

  appear() {
    this.element.classList.add('appear')
  }
}
