import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'cardHolderField', 'feedback', 'currentCardDescription', 'currentCardLast4Field', 'submitButton']
  static values = {
    url: String,
    disableWith: String
  }

  connect() {
    this.loadStripe();
  }

  loadStripe() {
    try {
      this.initStripe();
    } catch(exception) {
      if (exception instanceof ReferenceError) {
        if (/Stripe is not defined/.test(exception)) {
          let script = document.createElement('script');
          script.src = 'https://js.stripe.com/v3/';
          script.onload = ()=>{
            this.initStripe();
          }
          document.head.appendChild(script);
        } else {
          throw exception;
        }
      }
    }
  }

  initStripe() {
    this.stripe = Stripe(app.stripe_public_key);
    this.card = this.initStripeCard(this.stripe.elements());
    this.card.mount('#card-element');
    setTimeout(this.slideInFlash, 10);
  }

  submitForm(event){
    event.preventDefault();
    event.stopPropagation();
    this.onBeforeSubmitForm();

    const billingDetails = {
      name: this.cardHolderFieldTarget.value
    }

    if (billingDetails.name == '') {
      this.onStripeError('Cardholder name needs to be filled in');
    } else {
      this.stripe.createPaymentMethod("card", this.card, { billing_details: billingDetails}).then(result => {
        this.stripeResponseHandler(result);
      });
    }
  }

  async stripeResponseHandler(result){
    if (result.error) {
      this.onStripeError(result.error.message);
    } else {
      let card = result.paymentMethod.card;
      let paymentMethod = result.paymentMethod.id;
      let formData = new FormData();
      formData.append("payment_method", paymentMethod);

      const response = await post(this.urlValue, {
        body: formData
      });

      if (response.ok) {
        const subscription = await response.json;
        const { pending_setup_intent, status } = subscription;
        if ((status === "active" || status === "trialing" || status === 'unpaid') && pending_setup_intent == null) {
          this.onStripeSuccess(card);
        } else {
          const { client_secret, status } = subscription.pending_setup_intent;
          if (status === 'requires_action') {
            const result = await this.stripe.handleCardSetup(client_secret)
            if (result.error) {
              this.onStripeError(result.error.message);
            } else {
              this.onStripeSuccess(card);
            }
          } else if (status === 'requires_payment_method') {
            this.onStripeError('Adding Credit Card failed, please try again with a different card.');
          }
        }
      } else {
        if (response.statusCode === 422) {
          const json = await response.json;
          this.onStripeError(json.message);
        }
      }
    }
  }

  onBeforeSubmitForm() {
    this.removeErrors();
    this.submitButtonTarget.disabled = true;
    this.submitButtonText = this.submitButtonTarget.innerHTML;
    this.submitButtonTarget.innerHTML = this.disableWithValue;
  }

  onAfterSubmitForm() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.innerHTML = this.submitButtonText;
  }

  onStripeSuccess(card) {
    this.onAfterSubmitForm();
    this.showFlashMessage('Your card has successfully been updated');
    if (this.hasCurrentCardLast4FieldTarget) {
      this.currentCardLast4FieldTarget.innerHTML = card.last4;
    }
    this.formTarget.reset();
    this.card.clear();
  }

  onStripeError(message) {
    this.onAfterSubmitForm();
    this.feedbackTarget.classList.add('error');
    this.feedbackTarget.innerHTML = message;
  }

  removeErrors() {
    this.feedbackTarget.innerHTML = '';
    this.feedbackTarget.classList.remove('error');
  }

  showFlashMessage(message){
    let flashContainer = document.querySelector('.flash_container');
    if (flashContainer) {
      flashContainer.innerHTML = `<div class='flash notice' data-controller='flash' data-flash-closable='true'>${ message }</div>`;
    }
  }

  initStripeCard(elements) {
    const style = {
      base: {
        iconColor: '#3FD9B2',
        color: '#555555',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: 'proxima-nova, Arial',
        fontSize: '14px',
        '::placeholder': {
          color: '#CCCCCC'
        }
      },
      invalid: {
        iconColor: '#e1595e',
        color: '#e1595e'
      }
    }
    return elements.create('card', {style: style});
  }
}
