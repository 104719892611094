import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [ "trigger" ]

  connect() {
    const config = {
      trigger: 'mouseenter focus click'
    }
    tippy(this.triggerElement, config)
  }

  get triggerElement() {
    if (this.hasTriggerTarget) {
      return this.triggerTarget;
    } else {
      return this.element;
    }
  }
}
