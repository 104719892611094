import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputField']

  clearFormInput(event) {
    event.preventDefault()

    const customEvent = new Event('input')

    this.inputField.value = null
    this.inputField.dispatchEvent(customEvent)
    Turbo.clearCache();
  }

  get inputField(){
    if (this.hasInputTarget) {
      return this.inputFieldTarget
    } else {
      return this.element.querySelector('input')
    }
  }
}