import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['initialInput', 'field', 'input']
  static values = {
    valuesToShow: Array,
    pattern: String
  }
  connect() {
    this.toggleField(this.initialInputTarget.value)
  }

  select(event) {
    this.toggleField(this.initialInputTarget.value)
  }

  toggleField(value) {
    if (this.valuesToShowValue.includes(value)) {
      this.showField(value)
    } else {
      this.hideField(value)
    }
  }

  showField(value) {
    this.fieldTarget.classList.remove('hidden')
    this.inputTarget.removeAttribute('readonly')
    if (this.hasPatternValue) {
      const placeholder = this.patternValue.replace(':value', value)
      this.inputTarget.setAttribute('placeholder', placeholder)
    }
  }

  hideField(value) {
    if (this.hasPatternValue) {
      this.inputTarget.setAttribute('placeholder', this.patternValue.replace(':value', value))
    }
    this.fieldTarget.classList.add('hidden')
    this.inputTarget.setAttribute('readonly', '')
    }
}
