import { Controller } from "@hotwired/stimulus"

// this controller detects the timezone and sets it on a form input/select field
export default class extends Controller {
  static targets = ['input']

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone?.replace(/\w+\//,'')
    }
  }
}
